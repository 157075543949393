.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


tst_j {
	background-color: rgba(255, 255, 0, 0.61);
}

.textoComum {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	color: rgba(0, 0, 0, 0.87);
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
}

.word-download {
    width: 16px;
    height: 16px;
    background-image: url(../img/word_ico.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: bottom;
    cursor: pointer;
    margin-left: 5px
}

.pdf-download {
    width: 16px;
    height: 16px;
    background-image: url(../img/icon-pdf.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: bottom;
	cursor: pointer;
    margin-left: 5px
}

.open_in_new_window {
  width: 16px;
  height: 16px;
  background-image: url(../img/open_in_new_window.png);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  margin-left: 5px
}

.altura-card-padrao {
  height: 100%;
  margin: 5px;
}

.altura-automatica {
  margin: 5px;
}