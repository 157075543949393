fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.mdl * {
  outline: none;
}

/* mdl is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */
.mdl .dropdown, .mdl a, .mdl.tag-list, .mdl ul {
  width: 100%;
}

.mdl li {
  width: 90%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  content: "";
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:hover:after {
  content: "\25BC"
}

.mdl input {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 200;
  padding-top: 0.5rem;
  width: 100%;
}

.mdl {
  border-bottom: 1px solid rgba(0, 0, 0, 0.50);
  padding-bottom: 1px;
}

.mdl:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  border-width: 2px;
  padding-bottom: 0;
}

.mdl .drodown:active, .mdl .dropdown:hover, .mdl .drodown {
  outline: none !important;
}

.mdl .dropdown a {
  border: none !important;
  padding: 0 !important;
}

.dropdown .search {
  border: none !important;
}

.mdl .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl .checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #2196f3;
}

.dropdown-content {
  overflow-y: auto;
  max-height: 300px;
}